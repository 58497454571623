<template>
    <LiefengContent>
        <template v-slot:title>中奖名单</template>
        <template v-slot:toolsbarLeft>
            <Input style="width: 200px" v-model="account" placeholder="请输入联系电话" />
            <Button
                type="info"
                @click="
                    () => {
                        getList()
                    }
                "
            >
                查询
            </Button>
            <Button
                type="default"
                @click="
                    () => {
                        restData()
                    }
                "
            >
                清空条件
            </Button>
        </template>
        <template v-slot:contentArea>
            <div class="leftMenu">
                <LiefengTable
                    :talbeColumns="tableColumns"
                    :tableData="tableData"
                    :loading="loading"
                    :fixTable="true"
                    :curPage="page"
                    :total="total"
                    :pagesizeOpts="[20, 30, 50, 100]"
                    :page-size="pageSize"
                    @hadlePageSize="hadlePageSize"
                    @tableSelect="onSelect"
                ></LiefengTable>
            </div>
        </template>
    </LiefengContent>
</template>

<script>
//@route('/test15minmanage')
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
export default {
    components: {
        LiefengContent,
        LiefengTable,
    },
    data() {
        return {
            selectList: [
                {
                    dictValue: "浏览",
                    dictKey: "0",
                },
                {
                    dictValue: "拨打电话",
                    dictKey: "1",
                },
                {
                    dictValue: "定位",
                    dictKey: "2",
                },
            ],
            tableData: [],
            page: 1,
            pageSize: 20,
            total: 0,
            loading: false,
            tableColumns: [
                {
                    title: "领取状态",
                    minWidth: 100,
                    key: "acquireStatus",
                    align: "center",
                },
                {
                    title: "姓名",
                    minWidth: 100,
                    key: "userName",
                    align: "center",
                },
                {
                    title: "联系电话",
                    minWidth: 100,
                    key: "account",
                    align: "center",
                },
                {
                    title: "中奖时间",
                    minWidth: 100,
                    key: "winTime",
                    align: "center",
                    render: (h, params) => {
                        return h("div", {}, params.row.winTime && params.row.winTime != "" ? this.$core.formatDate(new Date(params.row.winTime), "yyyy-MM-dd hh:mm:ss") : "")
                    },
                },
                {
                    title: "中奖结果",
                    minWidth: 100,
                    key: "itemName",
                    align: "center"
                },
                {
                    title: "领取时间",
                    minWidth: 100,
                    key: "acquireTime",
                    align: "center",
                    render: (h, params) => {
                        return h("div", {}, params.row.acquireTime && params.row.acquireTime != "" ? this.$core.formatDate(new Date(params.row.acquireTime), "yyyy-MM-dd hh:mm:ss") : "")
                    },
                },
                {
                    title: "社区",
                    minWidth: 100,
                    key: "community",
                    align: "center",
                },
                {
                    title: "街道",
                    minWidth: 100,
                    key: "street",
                    align: "center",
                },
                {
                    title: "操作",
                    align: "center",
                    width: 100,
                    fixed: "right",
                    render: (h, params) => {
                        return h(
                            "div",
                            {
                                style: {
                                    textAlign: "center",
                                },
                            },
                            [
                                h(
                                    "span",
                                    {
                                        on: {
                                            click: async () => {
                                                this.$Modal.confirm({
                                                    title: "领取提醒",
                                                    content: "是否进行领取操作",
                                                    okText: "确认",
                                                    cancelText: "取消",
                                                    onOk: () => {
                                                        this.$post("/gateway/api/syscenic/pc/question/claimReward", {
                                                            recordId: params.row.recordId,
                                                        }).then(res => {
                                                            if (res && res.code == 200) {
                                                                this.$Message.success({
                                                                    content: "领取成功",
                                                                    background: true,
                                                                })
                                                                this.getList()
                                                                return
                                                            }
                                                        })
                                                    },
                                                })
                                            },
                                        },
                                        style: {
                                            width: "65px",
                                            marginRight: "10px",
                                            textAlign: "center",
                                            color: "#2d8cf0",
                                            cursor: "pointer",
                                            userSelect: "none",
                                            display: params.row.acquireTime && params.row.acquireTime != "" ? "none" : "block",
                                        },
                                    },
                                    "领取" //1001
                                ),
                            ]
                        )
                    },
                },
            ],
            account: "",
        }
    },
    methods: {
        restData() {
            this.actionType = ""
            this.page = 1
            this.getList()
        },
        hadlePageSize(obj) {
            this.pageSize = obj.pageSize
            this.page = obj.page
            this.getList()
        },
        getList() {
            this.loading = true
            this.$get("/gateway/api/syscenic/pc/question/listActivityRecordByPage", {
                page: this.page,
                pageSize: this.pageSize,
                topicId: this.$route.query.id,
                dataScopeId: "",
                account: this.account,
                acquireStatus: "",
            }).then(res => {
                if (res.code == 200 && res.dataList) {
                    this.tableData = res.dataList
                    this.total = res.maxCount
                }
                this.$Message.destroy()
                this.loading = false
            })
        },
    },
    async created() {
        this.getList()
    },
}
</script>

<style lang='less' scoped>
.demo-tree-render .ivu-tree-title {
    width: calc(100% - 34px);
    padding: 10px;
    & > span {
        & > span:first-of-type {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            width: 70%;
            display: inline-block;
        }
    }
}
.demo-tree-render .ivu-tree-arrow {
    padding: 10px;
}
.ivu-breadcrumb {
    background-color: #fff;
    line-height: 36px;
    padding: 0 20px;
    margin-bottom: 10px;
    position: relative;
}
.form {
    height: 100%;
    overflow: scroll;
}
.noContent {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f9f9f9;
    color: #999;
    font-size: 36px;
}
.validate {
    &::before {
        content: "*";
        display: inline-block;
        margin-right: 4px;
        line-height: 1;
        font-family: SimSun;
        font-size: 14px;
        color: #ed4014;
    }
}
.toolsbarRight {
    display: flex;
    padding-top: 10px;
}
</style>
